import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(() => ({
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: 13,
      boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.15)"
    }
  },
  button: {
    borderRadius: 13,
    textTransform: "none",
    padding: "8px 24px"
  },
  actions: {
    padding: "16px"
  },
  content: {
    padding: "16px"
  }
}));

const ConfirmationModal = ({ title, children, open, onClose, onConfirm }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="confirm-dialog"
      className={classes.dialog}
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent dividers className={classes.content}>
        <Typography>{children}</Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          onClick={() => onClose(false)}
          color="default"
          className={classes.button}
        >
          {i18n.t("confirmationModal.buttons.cancel")}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onClose(false);
            onConfirm();
          }}
          color="secondary"
          className={classes.button}
        >
          {i18n.t("confirmationModal.buttons.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
