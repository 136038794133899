import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import openSocket from "../../services/socket-io";
import toastError from "../../errors/toastError";
import { Dialog, DialogContent, Typography, Grid, Box, useMediaQuery, useTheme, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { i18n } from "../../translate/i18n";
import api from "../../services/api";

const QrcodeModal = ({ open, onClose, whatsAppId }) => {
	const [qrCode, setQrCode] = useState("");
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	useEffect(() => {
		const fetchSession = async () => {
			if (!whatsAppId) return;

			try {
				const { data } = await api.get(`/whatsapp/${whatsAppId}`);
				setQrCode(data.qrcode);
			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, [whatsAppId]);

	useEffect(() => {
		if (!whatsAppId) return;
		const socket = openSocket();

		socket.on("whatsappSession", data => {
			if (data.action === "update" && data.session.id === whatsAppId) {
				setQrCode(data.session.qrcode);
			}

			if (data.action === "update" && data.session.qrcode === "") {
				onClose();
			}
		});

		return () => {
			socket.disconnect();
		};
	}, [whatsAppId, onClose]);

	return (
		<Dialog 
			open={open} 
			onClose={onClose} 
			maxWidth="md" 
			scroll="paper"
			PaperProps={{
				style: {
					borderRadius: 13,
					boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
					position: 'relative'
				}
			}}
		>
			<IconButton
				onClick={onClose}
				style={{
					position: 'absolute',
					right: 8,
					top: 8,
					zIndex: 2
				}}
			>
				<CloseIcon />
			</IconButton>

			<DialogContent style={{ padding: 0 }}>
				<Grid container spacing={0} style={{ minHeight: isMobile ? 'auto' : '400px' }}>
					{!isMobile && (
						<Grid item xs={12} md={6}>
							<Box 
								p={3} 
								display="flex" 
								flexDirection="column" 
								justifyContent="center"
								height="100%"
							>
								<Typography variant="h6" color="primary" style={{ fontWeight: 600, marginBottom: 16 }}>
									Vincular sessão no WhatsApp Business
								</Typography>
								<Typography variant="body2" paragraph>
									Use o WhatsApp na plataforma WaSap para trocar mensagens com seus contatos
								</Typography>
								<Typography variant="body2" paragraph color="error" style={{ fontWeight: 500 }}>
									Atenção: Desvincule todos os setores da conexão antes de efetuar a leitura, para garantir que o dispositivo não envie o chatbot em conversas antigas.
								</Typography>
								<Typography component="div" variant="body2">
									<ol style={{ paddingLeft: 20, margin: 0 }}>
										<li>Abra o WhatsApp Business em seu smartphone</li>
										<li>Toque em Menu (representado por três pontinhos verticais) no Android ou em Definições ( representado por engrenagem) em iPhones</li>
										<li>Toque em 'Dispositivos Conectados' e depois em 'Conectar Dispositivo'</li>
										<li>Para ler o QR-Code, aponte a câmera do seu smartphone para esta tela</li>
										<li>Aguarde a Conexão ser Estabelecida.</li>
									</ol>
								</Typography>
							</Box>
						</Grid>
					)}
					<Grid item xs={12} md={6}>
							<Box 
								p={3} 
								display="flex" 
								flexDirection="column" 
								alignItems="center" 
								justifyContent="center"
								height="100%"
							>
								<Typography color="primary" gutterBottom align="center" variant="body2">
									{i18n.t("qrCode.message")}
								</Typography>
								{qrCode ? (
									<Box mt={2}>
										<QRCode value={qrCode} size={256} />
									</Box>
								) : (
									<Typography variant="body1" style={{ marginTop: 16 }}>
										Aguardando QR Code...
									</Typography>
								)}
							</Box>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default React.memo(QrcodeModal);
