import React, { useState } from 'react';

const DocsPage = () => {
  const [loading, setLoading] = useState(true);

  return (
    <div 
      style={{ 
        width: '100%', 
        height: '100%',
        boxSizing: 'border-box',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      }}
    >
      <div style={{
        width: '100%',
        height: '100%',
        overflow: 'hidden'
      }}>
        {loading && (
          <div style={{ 
            padding: '20px',
            textAlign: 'center'
          }}>
            Carregando documentação...
          </div>
        )}
        <iframe
          src="https://app.wasap.com.br/guia-do-usuario/"
          title="Documentação Wasap"
          onLoad={() => setLoading(false)}
          style={{
            width: '100%',
            height: loading ? '0' : '100%',
            border: 'none',
            transition: 'height 0.3s ease'
          }}
        />
      </div>
    </div>
  );
};

export default DocsPage;
