import { toast } from "react-toastify";
import { i18n } from "../translate/i18n";

const toastError = err => {
	const errorMsg = err.response?.data?.message || err.response.data.error;
	
	const toastOptions = {
		toastId: errorMsg,
		position: "top-right",
		autoClose: 2000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		style: {
			borderRadius: '13px',
			background: '#FF3B3B',
			color: '#fff',
			boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
			fontSize: '14px',
			padding: '12px 20px'
		}
	};

	if (errorMsg) {
		if (i18n.exists(`backendErrors.${errorMsg}`)) {
			toast.error(i18n.t(`backendErrors.${errorMsg}`), toastOptions);
		} else {
			toast.error(errorMsg, toastOptions);
		}
	} else {
		toast.error("Ocorreu um erro!!!", toastOptions);
	}
};

export default toastError;
